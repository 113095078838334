interface iFaqItem {
  no: string;
  title: string;
  details: string;
}

type Faq = iFaqItem[];

export const faq: Faq = [
  {
    no: 'fnq1',
    title: '비상주사무실은 법적으로 문제가 없나요',
    details: `온잇공유오피스는 100% 합법적인 사무실이며, 정식 임대업 법인사업자입니다.`,
  },
  {
    no: 'fnq2',
    title: '모든 업종이 등록 가능한가요?',
    details: `제2종 근린생활시설로써 모든 업종의 사업자등록이 가능합니다.
온라인 쇼핑몰, 도소매업, 서비스업, 정보통신업, 건설업, 기술서비스업 등.
`,
  },
  {
    no: 'fnq3',
    title: '계약은 어떻게 진행되나요?',
    details: `[01. 서비스 선택]
    비상주 / 상주 / 계약기간
    [02. 임대료 결제]
    선입금(계좌이체)
    [03. 전자계약서(임대차계약서) 작성]
    휴대폰 또는 이메일로 작성 및 전송
    (법인설립의 경우 설립 이후 진행)
    [04. 완료]
    사업자등록 . 후세금계산서 발행
`,
  },
  {
    no: 'fnq4',
    title: '비대면 계약도 가능한가요?',
    details: `대면/비대면 계약 모두 가능합니다. 
    직접 오피스에 내방하여 대면 계약을 할 수 있고, 내방이 불가할 경우 비대면 계약도 가능합니다.
    10분 내외의 신속한 응대로 계약 진행이 이루어집니다.
    `,
  },
  {
    no: 'fnq5',
    title: '기타 주의사항',
    details: `대부업은 상주 오피스만 가능합니다. 
    인허가가 필요한 업종은 상담 시 꼭 말씀해주세요.`
  },
];


export default faq;
export type { iFaqItem };