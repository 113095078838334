const officeService = {
  // 원당
  "onedang": [
    {
      main: '프리미엄 상주실',
      sub: '단독사용 5평 큰 면적 / 개별 냉난방\n공유오피스 유일 상주사무실',
    },
    {
      main: '프리미엄 비상주실',
      sub: '비상주 전용 독립실 운영\n실력있는 법무사|세무사 제휴',
    },
    {
      main: '실사지원 | 우편물 관리(무료)',
      sub: '관공서, 금융기관, 협회 등 실태조사 대응\n매일 우편물 도착 알림',
    }
  ],
  // 평택
  "pyeongtaek": [
    {
      main: '비상주 주소지 임대서비스',
      sub: '최저가/10분내 전자계약 진행\n비과밀억제권역으로 세금 혜택',
    },
    {
      main: '프리미엄 스터디공간',
      sub: '실내|외 휴게실 및 카페테리어\n편백나무 인테리어, 전좌석 시디즈',
    },
    {
      main: '실사지원 | 우편물 관리(무료)',
      sub: '관공서, 금융기관, 협회 등 실태조사 대응\n매일 우편물 도착 알림',
    }
  ],
  // 영등포
  "yeongdeungpo": [
    {
      main: '넓은 개인공간 및 개별 냉난방',
      sub: '전기세 관리비 일체 무료\n 3중보안시스템(지문,cctv,개별 도어락)',
    },
    {
      main: '고객을 생각하는 서비스',
      sub: '문제상황 즉각 조치 가능한 매니저 상주\n프린터 팩스 복합기, 사무용품, 정수기 완비',
    },
    {
      main: '실사지원 | 우편물 관리(무료)',
      sub: '관공서, 금융기관, 협회 등 실태조사 대응\n매일 우편물 도착 알림',
    }
  ],
  // 원주점
  "wonju": [
    {
      main: '프리미엄 상주실',
      sub: '단독사용 5평 큰 면적 / 개별 냉난방\n무료 유무선 인터넷, 24시보안 관리, 도어락출입관리',
    },
    {
      main: '프리미엄 비상주실',
      sub: '비상주 전용 독립실 운영\n실력있는 법무사|세무사 제휴',
    },
    {
      main: '실사지원 | 우편물 관리(무료)',
      sub: '관공서, 금융기관, 협회 등 실태조사 대응\n매일 우편물 도착 알림',
    }
  ],
  // 대구점
  "daegu": [
    {
      main: '사업 운영비용 절감',
      sub: `사무실 보증금, 비싼 임대료 절감\n신뢰할 수 있는 상업용 주소 제공`,
    },
    {
      main: '유연한 운영 가능',
      sub: '실사대응, 우편물 관리\n오프라인 공간 없이도 비즈니스 주소 확보',
    },
    {
      main: '계약 체결 단 10분',
      sub: '개인사업자 : 신규등록 | 주소이전\n법인사업자 : 법인설립 | 주소이전',
    },
  ],
  // 안양점
  "anyang": [
    {
      main: '사업 운영비용 절감',
      sub: `사무실 보증금, 비싼 임대료 절감\n신뢰할 수 있는 상업용 주소 제공`,
    },
    {
      main: '유연한 운영 가능',
      sub: '실사대응, 우편물 관리\n오프라인 공간 없이도 비즈니스 주소 확보',
    },
    {
      main: '계약 체결 단 10분',
      sub: '개인사업자 : 신규등록 | 주소이전\n법인사업자 : 법인설립 | 주소이전',
    },
  ],
};

export default officeService;
