const benefit = {
  why: {
    title: "✨비상주 사무실은 이런 분들에게 필요합니다✨",
    when: [
      "법인 / 개인 사업자 등록할 때",
      "외근 위주의 사업으로 사무실 사용 빈도가 현저히 낮은 경우",
      "사업자 등록을 위한 임대차계약서가 필요할 때",
      "명함의 주소지가 필요할 때",
      "기존 사무실에서 사업자 등록이 불가한 경우",
      "기타 광고를 위한 사업자등록이 필요한 경우",
      "과밀억제권역 제외 구역의 사무실로 세금 혜택을 받고 싶은 경우",
      "스마트스토어 / 쿠팡 등의 전자상거래업 사업자 등록이 필요한 경우",
    ],
  },
  valueUp: {
    subTitle: `어려운 상황 속에서도 인생을 멋지게 꾸려나가는
모든 대표님을 응원하는 마음을 담았습니다.`,
    title: "입주사 대표님들의 인생을 응원합니다!",
    detail: [
      "리모델링을 통해 쾌적한 사무실 환경 제공",
      "최신식 개별 냉난방 시스템 무료 사용 가능",
      "책상, 의자, 무료 와이파이, 각종 사무기기, 탕비실 등",
      "다년간의 노하우로 비상주사무실 실사대응 완벽 가능"
    ]
  },
};

export default benefit;
