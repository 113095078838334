import information from "./information";

interface iSlogan {
  sub: string;
  main: string;
  mainType: string[];
  details: string;
}

interface iRouter {
  label: string;
  path: string;
}

type iIntro = Record<iRouter["path"], iIntroDetail>

interface iIntroDetail {
  path: string;
  label: string;
  title: string;
  detail: string;
  tel: string;
  scroll: string;
}

interface iFooter {
  naver: string;
  policy1: string;
  policy2: string;
  copyright: string;
}

interface iLanding {
  slogan: iSlogan;
  intro: iIntro;
  footer: iFooter;
}

const Landing: iLanding = {
  slogan: {
    sub: "모든 대표님들의 멋진 앞날을 응원하는 ON!T",
    main: "공유오피스 & 스터디카페",
    mainType: [
      "편리한 공유오피스",
      "가성비 상주|비상주오피스",
      "편안한 환경의 스터디카페",
    ],
    details:
      "온잇 공유오피스는 다년간의 투자 경험과 노하우를 지닌 세무 실무사가 함께 합니다.\n법인 설립, 사업자등록, 임대차 계약, 실사 대응, 우편물 관리 등 모든 방면에서 서포트가 가능합니다.",
  },
  intro: {
    "/": {
      label: "지점을 선택하세요",
      path: "",
      title: "",
      detail: "",
      tel: "",
      scroll: ""
    },
    "onedang": {
      path: "onedang",
      label: "인천 원당 1호점",
      title: "인천 원당점(비과밀억제권역)",
      detail: `온잇 공유오피스는 여러분의 비즈니스에 최적화된 업무 공간을 제공합니다.
인천검단신도시에 인접해있으며 상주/비상주 사무실을 합리적인 가격으로 이용할 수 있습니다.`,
      tel: "032)715-7555",
      scroll: "오픈 기념 이벤트 특가를 놓치기 싫다면?"
    },
    "pyeongtaek": {
      path: "pyeongtaek",
      label: "경기 평택 2호점",
      title: "경기 평택점(비과밀억제권역)",
      detail: `법인|개인 사업자 등록을 위한 주소지제공 서비스를 전국 최저가로 제공합니다.
맞춤책상과 전좌석 시디즈 의자를 갖춘 편리한 업무 및 스터디 공간으로 이용할 수 있습니다.`,
      tel: "031)373-6128",
      scroll: "오픈 기념 이벤트 특가를 놓치기 싫다면?"
    },
    "yeongdeungpo": {
      path: "yeongdeungpo",
      label: "서울 영등포구청 3호점",
      title: "영등포구청점(역세권 도보5분)",
      detail: `최신인테리어와 넓은 1인실,2인실을 가성비 있는 가격으로 상주 사무실을 이용 가능합니다.
비상주 사무실은 오픈행사 영등포 최저가로 할인 및 추가제공합니다(변동). 
      `,
      tel: "02)2633-7004",
      scroll: "오픈 기념 이벤트 특가를 놓치기 싫다면?"
    },
    "wonju": {
      path: "wonju",
      label: "강원 원주 4호점",
      title: "강원 원주점(비과밀억제권역)",
      detail: `강원 원주점은 비과밀억세권역으로 각종 세제혜택에 유리합니다.
상주/비상주 오피스를 전국에서 가장 저렴한 가격으로 이용할 수 있습니다.`,
      tel: "010-4239-8279",
      scroll: "오픈 특가 이벤트 진행 중!✨✨"
    },
    "daegu": {
      path: "daegu",
      label: "대구 칠곡 5호점",
      title: "대구 칠곡점(비과밀억제권역)",
      detail: `대구 칠곡점은 학정역 근처에 인접해있습니다.
상주(준비중)/비상주 오피스를 합리적인 가격으로 이용할 수 있습니다.`,
      tel: "0507-1478-5053",
      scroll: "오픈 특가 이벤트 진행 중!✨✨"
    },
    "anyang": {
      path: "anyang",
      label: "경기 안양역 7호점",
      title: "경기 안양역점",
      detail: `안양역 도보 5분 거리의 안양 1번가 내에 위치하였습니다.
전좌석 고급 시디즈 의자로 구성되어 편안하고 넓은 사무실을 합리적인 가격으로 이용할 수 있습니다.
`,
      tel: "031-441-8282",
      scroll: "오픈 특가 0원 이벤트 진행 중!✨✨"
    },
  },
  footer: {
    naver: "https://naver.me/GXAEbpo8",
    policy1: "개인정보취급방침 | ",
    policy2: "이용약관",
    copyright: "온잇 공유오피스 All right reserved.",
  },
};

export default Landing;
export type { iIntroDetail, iLanding }
